import PlanImage from '../images/plan.png'
import styles    from '../styles/Plan_Page.module.sass'

const Plan_Page = () => {
	return (
		<div className={styles.plan_page}>
			{/*Image*/}
			<div className={styles.image}>
				<img
					src={PlanImage}
					alt={'Image du plan'}
				/>
			</div>
		</div>
	)
}

export default Plan_Page
